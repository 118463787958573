.SlideFromRight-appear {
    transform: translateX(300px);
}

.SlideFromRight-appear-active {
    transform: translateX(0);
    transition: all .3s ease-in;
}

.SlideFromRight-appear-done {
    transform: translateX(0);
    transition: all .4s ease-in;
}

.SlideFromRight-enter {
    transform: translateX(450px);
}
.SlideFromRight-enter-active {
    transform: translateX(0);
    transition: all .4s ease-in;
}

.SlideFromRight-exit {
    transform: translateX(0);
}
.SlideFromRight-exit-active {
    transform: translateX(-450px);
    transition: all .4s ease-in;
} 