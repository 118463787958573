
.homeCSS {
  background-image: 
    url("/worker_png/cleaner woman.png"),
    url("/worker_png/hm_circular.png");
  
  background-repeat: no-repeat;
  background-position:
    10% 50%,
    90% 50%;
  
    background-size: 
      400px,
      280px;

}


.workerPane{
  
  background-image: url(/worker_png/MainPage2.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 200px;

}