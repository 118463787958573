.normal_border {
    border-radius: 0!important;
    border: 0 !important;
}

.left_inset_shadow{
    box-shadow: inset 3px 0 9px -7px rgba(0,0,0,0.7) !important;
    border-radius: 0px!important;    
}

.form_box_shadow{
    box-shadow: 4px 4px 5px grey !important;
}

.gen_box_shadow{
  -webkit-box-shadow: 11px 11px 5px -6px rgba(0,0,0,0.19);
  -moz-box-shadow: 11px 11px 5px -6px rgba(0,0,0,0.19);
  box-shadow: 11px 11px 5px -8px rgba(0,0,0,0.19);
  padding: 40px;
  border: 1px solid gainsboro;
  border-radius: 20px 10px;
  /* border-radius: 50px 20px; */
}


.gen_box_shadow_mobile{
    -webkit-box-shadow: 11px 11px 5px -6px rgba(0,0,0,0.19);
    -moz-box-shadow: 11px 11px 5px -6px rgba(0,0,0,0.19);
    box-shadow: 11px 11px 5px -6px rgba(0,0,0,0.19);
    border: 1px solid gainsboro;
    border-radius: 20px 8px;

  }

.button_no_border{
    box-shadow: none !important
}

.menu_item_narrow{
    padding: .0em 1.14285714em !important
}

.image_centered
{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 55%;
}

.div_padding
{
    padding-left: 10vw;
    padding-top: 20px;

}
