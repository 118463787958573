.FadeInCSS-appear {
    opacity: 0.01;
}

.FadeInCSS-appear-active {
    opacity: 1;
    transition: all .3s ease-in;
}

.FadeInCSS-enter {
    opacity: 0.01;
}
.FadeInCSS-enter-active {
    opacity: 1;
    transition: all .3s ease-in;
}

/* .FadeInCSS-exit {
    opacity: 1;
}
.FadeInCSS-exit-active {
    opacity: 0.01;
    transition: all 10ms ease-in;
}  */